import { initEnviroment, PrivateRoute, ThemeConfig } from "@enerbit/base";
import { Provider } from "react-redux";
import "./assets/css/search.scss";
import { store } from "./store/store";
import Router from "./router/router";
import MainLayout from "./layout/main-layout";

// Inicializa el entorno
initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
});

export default function Root() {
    // useEffect(() => {
    //     // Montar el sidebar al iniciar el componente
    //     handleRegistryMicrofront({
    //         microName: "sidebar",
    //         customProps: { sidebarId: "single-spa-application:sidebar" },
    //         importName: "@enerBit/sidebar",
    //     });
    // }, []);

    // const dispatch = useDispatch<AppDispatch>();

    // useEffect(() => {
    //     const prevSearchs = localStorage.getItem("recentSearchs");

    //     if (!prevSearchs) {
    //         localStorage.setItem("recentSearchs", JSON.stringify([]));
    //         dispatch(setRecentSearchs([]));
    //     } else {
    //         dispatch(setRecentSearchs(JSON.parse(prevSearchs)));
    //     }
    // }, []);

    return (
        <Provider store={store}>
            <ThemeConfig>
                <PrivateRoute>
                    <MainLayout>
                        <Router />
                    </MainLayout>
                </PrivateRoute>
            </ThemeConfig>
        </Provider>
    );
}
