import { Box } from "@enerbit/base";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const MainLayout = ({ children }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        marginTop: "70px",
        marginLeft: "80px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "calc(99.6vw - 70px)",
          height: "calc(100vh - 70px)",
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            zIndex: "0",
            width: "100%",
            overflowY: "scroll",
            px: "50px",
          }}
        >
          {children}
        </Box>
      </Box>
    </div>
  );
};

export default MainLayout;
