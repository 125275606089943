import { Box, enerbitColors, Typography } from "@enerbit/base";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmptyResults from "../../components/Results/EmptyResults";
import Error from "../../components/Results/Error";
import ResultsGrid from "../../components/Results/ResultsGrid";
import ResultsSkeleton from "../../components/Results/ResultsSkeleton";
import SearchBox from "../../components/Search/SearchBox";
import { RootState } from "../../store/store";

const Results = () => {
  const { loading, error, results, query } = useSelector(
    (state: RootState) => state.search
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!query) {
      navigate("/");
    }
  }, []);

  return (
    <Box sx={{ mx: "-50px" }}>
      <SearchBox customsStyles={{ borderRadius: "unset" }} />
      <Box sx={{ py: "15px", px: "50px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 1,
          }}
        >
          <Typography
            sx={{
              color: enerbitColors.primary.main,
              fontSize: "28px",
            }}
          >
            Resultados
          </Typography>
        </Box>
        {loading && <ResultsSkeleton />}
        {!loading && !error && results.length > 0 && <ResultsGrid />}
        {!loading && !error && results.length === 0 && <EmptyResults />}
        {!loading && error && <Error />}
      </Box>
    </Box>
  );
};

export default Results;
