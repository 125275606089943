import {
  Box,
  CircularProgress,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import EmptyResults from "../../components/Results/EmptyResults";
// import Error from "../../components/Results/Error";
import ResultsGrid from "../../components/Results/ResultsGrid";
// import ResultsSkeleton from "../../components/Results/ResultsSkeleton";
import SearchBox from "../../components/Search/SearchBox";
import { RootState } from "../../store/store";

const Results = () => {
  const { loading, results, query } = useSelector(
    (state: RootState) => state.search
  );
  // const navigate = useNavigate();
  const [visibleCount, setVisibleCount] = useState(15);
  const observerRef = useRef<null | HTMLDivElement>(null);

  // useEffect(() => {
  //     if (!query) {
  //         navigate("/");
  //     }
  // }, [query, navigate]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && visibleCount < results.length) {
        setVisibleCount((prevCount) => prevCount + 15);
      }
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [visibleCount, results.length]);

  return (
    <Box
      sx={{ mx: "auto", width: "100%", ml: 5, mt: "50px", marginRight: "40px" }}
    >
      <SearchBox customsStyles={{ borderRadius: "unset" }} />
      <Box sx={{ py: "15px" }}>
        {results.length > 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  color: enerbitColors.primary.main,
                  fontSize: "28px",
                }}
              >
                Resultados
              </Typography>
            </Box>

            <ResultsGrid visibleCount={visibleCount} />
            <div
              ref={observerRef}
              style={{
                height: "20px",
                background: "transparent",
              }}
            />
          </>
        ) : (
          !loading &&
          query && (
            <Typography color="error" sx={{ my: 2 }}>
              Sin resultados
            </Typography>
          )
        )}

        <Box
          sx={{
            display: "flex",
            justifyItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {loading && <CircularProgress sx={{ my: 3 }} />}
        </Box>
      </Box>
    </Box>
  );
};

export default Results;
