import { Results } from "../../models/results";
import ChargingStationOutlinedIcon from "@mui/icons-material/ChargingStationOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";

export const meterResultSchema: Results = {
    icon: {
        icon: <ChargingStationOutlinedIcon sx={{ color: "#FFF" }} />,
        bgcolor: "#8B76D6",
    },
    name: "Medidor",
    mainColor: "#53358E",
    labels: [
        { name: "Serial", key: "meter_serial", isMain: true },
        { name: "Id", key: "id", icon: <PrivacyTipOutlinedIcon /> },
    ],
    url: { base: "#/reading-records", pathParams: ["meter_serial"] },
};
