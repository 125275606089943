import { PrivateRoute, ThemeConfig, initEnviroment } from "@enerbit/base";
import { Provider } from "react-redux";
import "./assets/css/search.scss";
import MainLayout from "./layout/main-layout";
import Router from "./router/router";
import { store } from "./store/store";

// Inicializa el entorno
initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export default function Root() {
  // const dispatch = useDispatch<AppDispatch>();

  // useEffect(() => {
  //     const prevSearchs = localStorage.getItem("recentSearchs");

  //     if (!prevSearchs) {
  //         localStorage.setItem("recentSearchs", JSON.stringify([]));
  //         dispatch(setRecentSearchs([]));
  //     } else {
  //         dispatch(setRecentSearchs(JSON.parse(prevSearchs)));
  //     }
  // }, []);

  return (
    <Provider store={store}>
      <ThemeConfig>
        <PrivateRoute>
          <MainLayout>
            <Router />
          </MainLayout>
        </PrivateRoute>
      </ThemeConfig>
    </Provider>
  );
}
