import { Grid } from "@enerbit/base";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ResultsCard from "./ResultsCard";

const ResultsGrid = ({ visibleCount }: { visibleCount: number }) => {
    const { results } = useSelector((state: RootState) => state.search);

    return (
        <Grid container spacing={2} xs={12} width={"100%"}>
            {results.slice(0, visibleCount).map((result) => (
                <Grid item key={result.id} xs={12} md={4}>
                    <ResultsCard result={result} />
                </Grid>
            ))}
        </Grid>
    );
};

export default ResultsGrid;
