import { Box, Chip } from "@enerbit/base";
import { Results } from "../../models/results";
import IconContainer from "./IconContainer";

interface Props {
    resultSchema: Results;
}

const ResultIcon = ({ resultSchema }: Props) => {
    const { icon, name, mainColor } = resultSchema;
    return (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <IconContainer bgcolor={icon.bgcolor}>{icon.icon}</IconContainer>
            <Chip
                variant="outlined"
                label={name}
                size="small"
                sx={{
                    color: mainColor,
                    borderColor: mainColor,
                }}
            />
        </Box>
    );
};

export default ResultIcon;
