import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ALLOWED_ENTITIES, PREV_SEARCH_KEY } from "../../common";
import { ISearchState } from "../../models";
import { EEntities, IResultsResponse } from "../../models/results";
import { getSearch } from "../thunks/search";

const initialState: ISearchState = {
    query: "",
    error: false,
    loading: false,
    recentSearchs: [],
    results: [],
};

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setQueryText: (state, { payload }: PayloadAction<string>) => {
            state.query = payload;
        },
        setRecentSearchs: (state, { payload }: PayloadAction<string[]>) => {
            state.recentSearchs = payload;
        },
        setResults: (state, { payload }: PayloadAction<IResultsResponse>) => {
            const newResults = [...state.results, payload];

            state.results = newResults.filter((el) =>
                ALLOWED_ENTITIES.includes(el.entity as EEntities),
            );
        },
        clearResults: (state) => {
            state.results = [];
        },
        updateRecentSearch: (state, { payload }: PayloadAction<string>) => {
            if (!state.recentSearchs.includes(payload)) {
                state.recentSearchs.push(payload);
                localStorage.setItem(
                    PREV_SEARCH_KEY,
                    JSON.stringify(state.recentSearchs),
                );
            }
        },
    },
    extraReducers(builder) {
        builder.addCase(getSearch.pending, (state) => {
            state.loading = true;
            state.error = false;
            // state.results = [];
        });
        builder.addCase(getSearch.fulfilled, (state) => {
            state.loading = false;
            state.error = false;
        });
        builder.addCase(getSearch.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    },
});

export const {
    setQueryText,
    setRecentSearchs,
    updateRecentSearch,
    setResults,
    clearResults,
} = searchSlice.actions;
