import { ObjectKeyValue, Results } from "../../models/results";
import BorderHorizontalIcon from "@mui/icons-material/BorderHorizontal";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import FlipToFrontOutlinedIcon from "@mui/icons-material/FlipToFrontOutlined";

const formatFrontierType = (metaData: ObjectKeyValue) => {
    const frontierType = metaData["frontier_type"];

    const frontierToName: Record<string, string> = {
        import_frontier: "Importadora",
        export_frontier: "Exportadora",
    };

    return frontierToName[frontierType];
};

export const frontierResultSchema: Results = {
    icon: {
        icon: <BorderHorizontalIcon sx={{ color: "#FFF" }} />,
        bgcolor: "#6747B5",
    },
    name: "Frontera",
    mainColor: "#6747B5",
    labels: [
        { name: "Código frontera", key: "frontier_xm_code", isMain: true },
        { name: "Id", key: "id", icon: <PrivacyTipOutlinedIcon /> },
        {
            name: "Tipo de frontera",
            key: "frontier_type",
            icon: <FlipToFrontOutlinedIcon />,
            formatFn: formatFrontierType,
        },
    ],
    url: {
        base: "#/frontiers",
        pathParams: ["frontier_xm_code"],
    },
};
