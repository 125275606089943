import { HashRouter, Route, Routes } from "react-router-dom";
import SearchBitoria from "../pages/Search/SearchBitoria";
import Results from "../pages/Results/Results";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/store";
import { setRecentSearchs } from "../store/slices/search";
import { PREV_SEARCH_KEY } from "../common";

const Router = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const prevSearchs = localStorage.getItem(PREV_SEARCH_KEY);

        if (!prevSearchs) {
            localStorage.setItem(PREV_SEARCH_KEY, JSON.stringify([]));
            dispatch(setRecentSearchs([]));
        } else {
            dispatch(setRecentSearchs(JSON.parse(prevSearchs)));
        }
    }, []);
    return (
        <HashRouter basename="/search-bitoria">
            <Routes>
                <Route path="/" element={<SearchBitoria />} />
                <Route path="/results" element={<Results />} />
            </Routes>
        </HashRouter>
    );
};

export default Router;
