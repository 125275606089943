import EventSource from "react-native-sse";

import type {} from "@reduxjs/toolkit";
import { EventSourceListener } from "react-native-sse";
import { IResultsResponse } from "../models/results";
import { SSEEvent } from "../models/event-source";
import { getAuthInfo, refreshToken } from ".";
const baseUrl = process.env.REACT_APP_BASE_URL;

let es: EventSource<string>;

export const initializeEventSource = async (
    token: string,
    query: string,
    isClose = false,
) => {
    const url = new URL("/search/lookfor", baseUrl);
    url.searchParams.append("query", query);

    const connectEventSource = (token: string) => {
        es = new EventSource(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    connectEventSource(token);

    let items = [] as IResultsResponse[];

    const itemsPromise = new Promise<IResultsResponse[]>((resolve, reject) => {
        const listener = async (event: SSEEvent) => {
            if (isClose) {
                return () => {
                    es.removeEventListener(
                        "search",
                        listener as EventSourceListener<"search">,
                    );
                    es.removeEventListener("end", listener);
                    es.removeEventListener(
                        "error",
                        listener as EventSourceListener<"error">,
                    );
                    es.close();
                };
            }
            if (event.type === "search") {
                const data = JSON.parse(event.data);
                items = [...items, data];
                // dispatch(addOrders(data.data));
            } else if (event.type === "error") {
                if (event.xhrStatus === 401) {
                    es.removeEventListener(
                        "search",
                        listener as EventSourceListener<"search">,
                    );
                    es.removeEventListener("end", listener);
                    es.removeEventListener(
                        "error",
                        listener as EventSourceListener<"error">,
                    );
                    es.close();
                    const newToken = await refreshToken();
                    const authInfo = {
                        ...getAuthInfo(),
                        access_token: newToken,
                    };
                    localStorage.setItem("authInfo", JSON.stringify(authInfo));
                    connectEventSource(newToken!);
                    // Re-add the event listeners after reconnecting
                    es.addEventListener(
                        "search",
                        listener as EventSourceListener<"search">,
                    );
                    es.addEventListener("end", listener);
                    es.addEventListener(
                        "error",
                        listener as EventSourceListener<"error">,
                    );
                } else {
                    // dispatch(setError("Error cargando órdenes."));
                    // dispatch(setLoading(true));
                    reject(event.message);
                }
            } else if (event.type === "exception") {
                reject(event.message);
                es.removeEventListener(
                    "search",
                    listener as EventSourceListener<"search">,
                );
                es.removeEventListener("end", listener);
                es.removeEventListener(
                    "error",
                    listener as EventSourceListener<"error">,
                );
                es.close();
            } else if (event.type === "end") {
                es.removeEventListener(
                    "search",
                    listener as EventSourceListener<"search">,
                );
                es.removeEventListener("end", listener);
                es.removeEventListener(
                    "error",
                    listener as EventSourceListener<"error">,
                );
                es.close();
                resolve(items);
            }
        };

        es.addEventListener(
            "search",
            listener as EventSourceListener<"search">,
        );
        es.addEventListener("end", listener);
        es.addEventListener("error", listener as EventSourceListener<"error">);
    });

    return await itemsPromise;
};
