import { Box, enerbitColors, styled } from "@enerbit/base";

const ResultBox: any = styled(Box)(() => ({
    minWidth: "104px",
    border: `1px solid ${enerbitColors.primary.main}`,
    borderRadius: "12px",
    padding: "12px",
    cursor: "pointer",
}));

export default ResultBox;
