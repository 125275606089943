import { Box, enerbitColors, SxProps, Typography } from "@enerbit/base";
import { Results, IResultsResponse } from "../../models/results";

interface Props {
    resultSchema: Results;
    result: IResultsResponse;
}

const mainLabelStyles: SxProps = {
    color: enerbitColors.primary.main,
    fontWeight: 700,
    fontSize: "16px",
};
const secondaryLabelStyles: SxProps = {
    color: enerbitColors.neutral.main,
    fontSize: "16px",
};

const ResultData = ({ result, resultSchema }: Props) => {
    return (
        <>
            {resultSchema.labels.map((label, index) => (
                <Box
                    key={index}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mt: label.isMain ? 0 : 1,
                    }}
                >
                    {label.icon ?? null}
                    <Typography
                        sx={
                            label.isMain
                                ? mainLabelStyles
                                : secondaryLabelStyles
                        }
                    >
                        <strong>{label.name}: </strong>
                        {label.formatFn
                            ? label.formatFn(result.meta_data)
                            : result.meta_data[label.key]}
                    </Typography>
                </Box>
            ))}
        </>
    );
};

export default ResultData;
