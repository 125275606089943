import { Box } from "@enerbit/base";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const MainLayout = ({ children }: Props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          marginTop: "70px",
          marginLeft: "80px",
        }}
      >
        <Box
          sx={{
            overflow: "auto",
            position: "relative",
            display: "flex",
            width: "calc(99.6vw - 70px)",
            height: "calc(100vh - 70px)",
            backgroundColor: "white",
          }}
        >
          {children}
        </Box>
      </div>
    </>
  );
};

export default MainLayout;
