import { Box, enerbitColors, IconButton } from "@enerbit/base";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ResultsBox from "./ResultsBox";

import ResultData from "./ResultData";
import { RESULTS_PER_ENTITY } from "../../common";
import { IResultsResponse } from "../../models/results";
import ResultIcon from "./ResultIcon";
import { buildUrl } from "../../helpers";
import { navigateToUrl } from "single-spa";

interface Props {
    result: IResultsResponse;
}

const ResultsCard = ({ result }: Props) => {
    const resultSchema = RESULTS_PER_ENTITY[result.entity];

    const handleRedirection = () => {
        const { url } = resultSchema;
        const fullUrl = buildUrl(result.meta_data, url);
        navigateToUrl(fullUrl);
    };

    return (
        <ResultsBox onClick={handleRedirection}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <ResultIcon resultSchema={resultSchema} />
                <IconButton onClick={handleRedirection}>
                    <ArrowForwardIcon
                        sx={{ color: enerbitColors.warning.main }}
                    />
                </IconButton>
            </Box>
            <Box sx={{ mt: 1 }}>
                <ResultData resultSchema={resultSchema} result={result} />
            </Box>
        </ResultsBox>
    );
};

export default ResultsCard;
