import { Grid } from "@enerbit/base";
import ResultsCard from "./ResultsCard";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const ResultsGrid = () => {
    const { results } = useSelector((state: RootState) => state.search);
    return (
        <Grid container rowSpacing={2}>
            {results.map((result) => (
                <Grid item key={result.id} xs={12} md={4}>
                    <ResultsCard result={result} />
                </Grid>
            ))}
        </Grid>
    );
};

export default ResultsGrid;
