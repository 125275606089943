import { Box, enerbitColors, Typography } from "@enerbit/base";
import SearchBox from "../../components/Search/SearchBox";

const SearchBitoria = () => {
    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
                gap: 3,
            }}
        >
            <Box>
                <Typography
                    sx={{
                        color: enerbitColors.primary.main,
                        fontSize: "48px",
                        fontWeight: 700,
                    }}
                >
                    Te damos la bienvenida
                </Typography>
                <Typography
                    sx={{
                        color: enerbitColors.neutral[700],
                        fontSize: "28px",
                    }}
                >
                    Accede rápidamente a lo que necesitas
                </Typography>
            </Box>
            <Box>
                <SearchBox />
                <Typography
                    sx={{
                        mt: 1,
                        color: enerbitColors.neutral.main,
                        fontSize: "16px",
                    }}
                >
                    Te ayudará a encontrar fácilmente información sobre
                    fronteras, SmartBit, ESS, MP, lead, clientes y medidores.
                </Typography>
            </Box>
        </Box>
    );
};

export default SearchBitoria;
