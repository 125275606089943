import { enerbitColors } from "@enerbit/base";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import type { Results } from "../../models/results";

export const estatesResultSchema: Results = {
    icon: {
        icon: <MapsHomeWorkIcon sx={{ color: "#FFF" }} />,
        bgcolor: enerbitColors.secondary.orange.main,
    },
    name: "Dirección",
    mainColor: enerbitColors.secondary.orange.main,
    labels: [
        { name: "Dirección", key: "address", isMain: true },

        {
            name: "Departamento",
            key: "state",
            icon: <PlaceOutlinedIcon />,
        },
        {
            name: "Ciudad",
            key: "city",
            icon: <PlaceOutlinedIcon />,
        },
    ],
    url: {
        base: "#/states",
        pathParams: ["id"],
    },
};
