import {
    Autocomplete,
    Box,
    SearchIcon,
    SxProps,
    TextField,
    autocompleteClasses,
} from "@enerbit/base";

import { useDispatch, useSelector } from "react-redux";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useNavigate } from "react-router-dom";
import { clearResults, setQueryText } from "../../store/slices/search";
import { AppDispatch, RootState } from "../../store/store";
import { getSearch } from "../../store/thunks/search";
import SearchContainer from "./SearchContainer";

interface Props {
    customsStyles?: SxProps;
}

const SearchBox = ({ customsStyles }: Props) => {
    const { query, recentSearchs, loading, results } = useSelector(
        (state: RootState) => state.search,
    );

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(clearResults());

        if (!query) return;
        dispatch(getSearch({ query }));
        navigate("/results");
    };

    return (
        <SearchContainer sx={customsStyles}>
            <form onSubmit={handleSearch}>
                <Autocomplete
                    disablePortal
                    freeSolo
                    disabled={loading && !results}
                    // value={query}
                    options={recentSearchs}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option: string) => option}
                    renderOption={(props, option) => {
                        return (
                            <Box
                                component="li"
                                {...props}
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                    [`&.${autocompleteClasses.option}`]: {
                                        padding: "8px",
                                    },
                                }}
                            >
                                <AccessTimeIcon />
                                {option}
                            </Box>
                        );
                    }}
                    onChange={(_, value) => {
                        dispatch(setQueryText(value || ""));
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: <SearchIcon />,
                            }}
                            sx={{
                                width: "100%",
                                borderRadius: "14px",
                                background: "white",
                            }}
                        />
                    )}
                />
            </form>
        </SearchContainer>
    );
};

export default SearchBox;
