import { Box, enerbitColors } from "@enerbit/base";

const Error = () => {
    return (
        <Box
            sx={{
                padding: "12px",
                width: "100%",
                bgcolor: enerbitColors.error[100],
                color: enerbitColors.error.main,
                borderRadius: "8px",
            }}
        >
            Se presentó un error en la búsqueda. Inténtalo nuevamente.
        </Box>
    );
};

export default Error;
