import { EEntities, Results } from "../models/results";
import {
    appUserResultSchema,
    essResultSchema,
    frontierResultSchema,
    meterResultSchema,
    smartbitResultSchema,
} from "./results";
import { estatesResultSchema } from "./results/estates";

export const ALLOWED_ENTITIES: EEntities[] = [
    EEntities.SMARTBIT,
    EEntities.APP_USER,
    EEntities.FRT,
    EEntities.METER,
    EEntities.ESS,
    EEntities.ESTATE,
];

export const PREV_SEARCH_KEY = "recentSearchs";

export const RESULTS_PER_ENTITY: Record<string, Results> = {
    SMB: smartbitResultSchema,
    FRT: frontierResultSchema,
    METER: meterResultSchema,
    APP_USER: appUserResultSchema,
    ESS: essResultSchema,
    ESTATES: estatesResultSchema,
};
