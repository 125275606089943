import { Box, enerbitColors, styled } from "@enerbit/base";

const SearchContainer: any = styled(Box)(() => ({
    backgroundColor: enerbitColors.primary[100],
    padding: "16px",
    width: "100%",
    // height: "100px",
    // boxSizing: "border-box",
    borderRadius: "12px",
}));

export default SearchContainer;
