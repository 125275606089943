import EventSource from "react-native-sse";
import { EventSourceListener } from "react-native-sse";
import { getAuthInfo, refreshToken } from ".";
import { SSEEvent } from "../models/event-source";
import { IResultsResponse } from "../models/results";
import { setResults } from "../store/slices/search";

const baseUrl = process.env.REACT_APP_BASE_URL;

let es: EventSource<string> | null = null; // Permitir control explícito

export const initializeEventSource = async (
    token: string,
    query: string,
    dispatch: any,
) => {
    const url = new URL("/search/lookfor", baseUrl);
    url.searchParams.append("query", query);

    const connectEventSource = (token: string) => {
        es = new EventSource(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    // Cerrar conexión anterior si existe
    if (es) {
        es.close();
        es = null; // Liberar la referencia
    }

    connectEventSource(token);

    let items = [] as IResultsResponse[];

    const itemsPromise = new Promise<IResultsResponse[]>((resolve, reject) => {
        const listener = async (event: SSEEvent) => {
            if (event.type === "search") {
                const data = JSON.parse(event.data);
                items = [...items, data];
                dispatch(setResults(data));
            } else if (event.type === "error") {
                if (event.xhrStatus === 401) {
                    es?.close();
                    const newToken = await refreshToken();
                    const authInfo = {
                        ...getAuthInfo(),
                        access_token: newToken,
                    };
                    localStorage.setItem("authInfo", JSON.stringify(authInfo));
                    connectEventSource(newToken!);

                    // Re-agregar listeners
                    es?.addEventListener(
                        "search",
                        listener as EventSourceListener<"search">,
                    );
                    es?.addEventListener("end", listener);
                    es?.addEventListener(
                        "error",
                        listener as EventSourceListener<"error">,
                    );
                } else {
                    reject(event.message);
                }
            } else if (event.type === "exception") {
                reject(event.message);
                es?.close();
            } else if (event.type === "end") {
                es?.close();
                resolve(items);
            }
        };

        es?.addEventListener(
            "search",
            listener as EventSourceListener<"search">,
        );
        es?.addEventListener("end", listener);
        es?.addEventListener("error", listener as EventSourceListener<"error">);
    });

    return await itemsPromise;
};
