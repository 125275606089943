import { createAsyncThunk } from "@reduxjs/toolkit";
import { ALLOWED_ENTITIES } from "../../common";
import { getAcessToken } from "../../helpers";
import { initializeEventSource } from "../../helpers/event-source";
import { GetSearchParams } from "../../models";
import { EEntities, IResultsResponse } from "../../models/results";
import { updateRecentSearch } from "../slices/search";

export const getSearch = createAsyncThunk(
    "search/getSearch",
    async ({ query }: GetSearchParams, { rejectWithValue, dispatch }) => {
        const token = getAcessToken();

        if (!token) return rejectWithValue("Not token");

        (await initializeEventSource(
            token,
            query,
            dispatch,
        )) as IResultsResponse[];

        dispatch(updateRecentSearch(query));

        // return items.filter((el) =>
        //     ALLOWED_ENTITIES.includes(el.entity as EEntities),
        // );
    },
);
