import { Box, styled } from "@enerbit/base";

interface Props {
    bgcolor: string;
}

const IconContainer: any = styled(Box)<Props>(({ bgcolor }) => ({
    backgroundColor: bgcolor,
    height: "40px",
    width: "40px",
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
}));

export default IconContainer;
