import { Results } from "../../models/results";
import SettingsRemoteOutlinedIcon from "@mui/icons-material/SettingsRemoteOutlined";
import QrCodeIcon from "@mui/icons-material/QrCode";

export const smartbitResultSchema: Results = {
    icon: {
        icon: <SettingsRemoteOutlinedIcon sx={{ color: "#4F3689" }} />,
        bgcolor: "#DFDCF8",
    },
    name: "smartBit",
    mainColor: "#53358E",
    labels: [
        { name: "ID", key: "id", isMain: true },
        { name: "Device name", key: "name", icon: <QrCodeIcon /> },
    ],
    url: { base: "#/smartbits", pathParams: ["id"] },
};
