import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISearchState } from "../../models";
import { getSearch } from "../thunks/search";
import { PREV_SEARCH_KEY } from "../../common";

const initialState: ISearchState = {
    query: "",
    error: false,
    loading: false,
    recentSearchs: [],
    results: [],
};

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setQueryText: (state, { payload }: PayloadAction<string>) => {
            state.query = payload;
        },
        setRecentSearchs: (state, { payload }: PayloadAction<string[]>) => {
            state.recentSearchs = payload;
        },
        updateRecentSearch: (state, { payload }: PayloadAction<string>) => {
            if (!state.recentSearchs.includes(payload)) {
                state.recentSearchs.push(payload);
                localStorage.setItem(
                    PREV_SEARCH_KEY,
                    JSON.stringify(state.recentSearchs),
                );
            }
        },
    },
    extraReducers(builder) {
        builder.addCase(getSearch.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.results = [];
        });
        builder.addCase(getSearch.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.results = payload;
        });
        builder.addCase(getSearch.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.results = [];
        });
    },
});

export const { setQueryText, setRecentSearchs, updateRecentSearch } =
    searchSlice.actions;
