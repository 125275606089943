import { Box, enerbitColors } from "@enerbit/base";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const EmptyResults = () => {
    const { query } = useSelector((state: RootState) => state.search);

    return (
        <Box
            sx={{
                padding: "12px",
                width: "100%",
                bgcolor: enerbitColors.information[100],
                color: enerbitColors.information.main,
                borderRadius: "8px",
            }}
        >
            No se encontraron resultados para la búsqueda{" "}
            <strong>{query}</strong>
        </Box>
    );
};

export default EmptyResults;
