import { Box, Chip, IconButton, enerbitColors } from "@enerbit/base";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ResultsBox from "./ResultsBox";

import { navigateToUrl } from "single-spa";
import { RESULTS_PER_ENTITY } from "../../common";
import { buildUrl } from "../../helpers";
import { IResultsResponse } from "../../models/results";
import ResultData from "./ResultData";
import ResultIcon from "./ResultIcon";

interface Props {
    result: IResultsResponse;
}

const ResultsCard = ({ result }: Props) => {
    const resultSchema = RESULTS_PER_ENTITY[result.entity];

    const handleRedirection = () => {
        const { url } = resultSchema;
        const fullUrl = buildUrl(result.meta_data, url);
        navigateToUrl(fullUrl);
    };

    return (
        <ResultsBox>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <ResultIcon resultSchema={resultSchema} />
                <IconButton onClick={handleRedirection}>
                    <ArrowForwardIcon
                        sx={{ color: enerbitColors.warning.main }}
                    />
                </IconButton>
            </Box>
            <Box sx={{ mt: 1 }}>
                <ResultData resultSchema={resultSchema} result={result} />
            </Box>

            {result?.meta_data?.tag_name && (
                <Box
                    sx={{
                        // overflowX: "scroll",
                        display: "flex",
                        flexWrap: "nowrap",
                    }}
                >
                    {result?.meta_data?.tag_name.map((tag: string) => (
                        <Chip label={tag} key={tag} sx={{ mr: 1, mt: 1 }} />
                    ))}
                </Box>
            )}
        </ResultsBox>
    );
};

export default ResultsCard;
